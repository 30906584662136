import React, { useEffect, useState } from 'react';
import Helmet from './Helmet'; // Your custom Helmet component
import FetchSite from './FetchSite'; // Function that fetches site data

const SiteMeta = () => {
  const [siteData, setSiteData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const result = await FetchSite(); 
      
      setSiteData(result.data); 
    };

    fetchData();
  }, []);

  if (!siteData) {
    return <div>Loading...</div>; // If data is not yet available, show loading
  }

  // Construct the image URL if it's stored in S3 or any other storage system
  const ogImage = `https://d1vkr02c9udppg.cloudfront.net/${siteData.site_image.filename_disk}`;


  return (
    <div>
      {/* Add Helmet with dynamic site data */}
      <Helmet
        title={`Narrada Communications | ${siteData.site_tagline}`}
        description={siteData.site_description}
        ogImage={ogImage}
        ogUrl={siteData.site_url}
        keyword={siteData.site_keyword}
        tagline={siteData.site_tagline}
      />
    </div>
  );
};

export default SiteMeta;
