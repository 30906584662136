import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import NrdLogo from '../assets/img/og-narrada-logo.png';

const Header = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const navigate = useNavigate();
  const searchRef = useRef(null);

  const handleSearch = () => {
    if (searchTerm && searchTerm.trim()) {
      navigate(`/search?search=${encodeURIComponent(searchTerm.trim())}`); // Navigate to SearchList with the search term
    } else {
      console.error('Search term is undefined or empty');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleClickOutside = (e) => {
    if (searchRef.current && !searchRef.current.contains(e.target)) {
      setIsSearchVisible(false);
    }
  };

  useEffect(() => {
    if (isSearchVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSearchVisible]);

  return (
    <header className="header">
      <div className="logo">
        <Link to="/">
          <img src={NrdLogo} alt="Narrada" />
        </Link>
      </div>
      <div className='search lg:block hidden'>
      <i
        className="fa fa-search"
        onClick={handleSearch}
        style={{ cursor: 'pointer' }}
      ></i>
      <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value || '')}
            onKeyDown={handleKeyDown}
            placeholder=" "
          />
      </div>
      <div className="search lg:hidden">
        {!isSearchVisible && (
          <i
            className="fa fa-search"
            onClick={() => setIsSearchVisible(true)}
            style={{ cursor: 'pointer' }}
          ></i>
        )}
        <div className={`search-input ${isSearchVisible ? 'show' : ''}`} ref={searchRef}>
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value || '')}
            onKeyDown={handleKeyDown}
            placeholder=" "
          />
          <button onClick={handleSearch}><i
            className="fa fa-search"
            style={{ cursor: 'pointer' }}
          ></i></button>
        </div>
      </div>
    </header>
  );
};

export default Header;
