import React from 'react';
import { Link } from 'react-router-dom';

const AwardItem = ({ item }) => {
  // Ensure item is defined and has title/slug
  if (!item || !item.title || !item.slug) {
    return null;
  }

  // Extract the first image's directus_files_id
  let firstImageId = item.images?.[0]?.directus_files_id;

  const cloudFrontUrl = process.env.REACT_APP_DATA_CLOUDFRONT_URL; // Access CloudFront URL from .env

  // If directus_files_id is an object, extract its filename_disk property
  let firstImageFilename = '';
  if (typeof firstImageId === 'object' && firstImageId !== null) {
    firstImageFilename = firstImageId.filename_disk;
  }

  // Construct the URL for the first image
  const firstImageUrl = firstImageFilename ? `${cloudFrontUrl}${firstImageFilename}` : '';

  const detailPageLink = `/awards/${item.slug}`;

  return (
    <div className="carousel-item">
      {/* Single Image Display */}
      {firstImageUrl && (
        <img src={firstImageUrl} alt={item.title} className="carousel-image" />
      )}

      {/* Award Item Information */}
      <div className="carousel-text">
        <h2 className='mb-5'>{item.title}</h2>
        <Link to={detailPageLink}>
          <p className="underline text-sm">See more</p>
        </Link>
      </div>
    </div>
  );
};

export default AwardItem;
