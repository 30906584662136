import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'normalize.css';
import { HelmetProvider } from 'react-helmet-async';
import Header from './components/Header';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import About from './pages/About';
import WorkList from './pages/WorkList';
import ClientList from './pages/ClientList';
import ContentDetail from './pages/ContentDetail';
import LatestContent from './pages/LatestContent';
import Activities from './pages/Activities';
import Contact from './pages/Contact';
import Awards from './pages/Awards';
import Feed from './pages/Feed';
import ScrollToTop from './components/ScrollToTop';
import RightSidebar from './components/RightSidebar';
import SearchList from './pages/SearchList';
import { LatestContentProvider } from './components/LatestContentContext';
import AwardDetail from './pages/AwardDetail'; // Import the detail page
import DataShow from './pages/data-test'
import Helmet from './components/Helmet';

const App = () => {
  useEffect(() => {
    const handleResize = () => {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <HelmetProvider>
    <LatestContentProvider>
      <Router>
        <Helmet />
        <ScrollToTop />
        <Header />
        <Navbar />
        <RightSidebar />
        <div style={{ height: 'calc(var(--vh, 1vh) * 100)' }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/work" element={<WorkList />} />
            <Route path="/activities" element={<Activities />} />
            <Route path="/client" element={<ClientList />} />
            <Route path="/:category/:title" element={<ContentDetail />} />
            <Route path="/latest" element={<LatestContent />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/awards" element={<Awards />} />
            <Route path="/awards/:slug" element={<AwardDetail />} />
            <Route path="/feed" element={<Feed />} />
            <Route path="/search" element={<SearchList />} />
            <Route path="/datatest" element={<DataShow />} />
          </Routes>
        </div>
      </Router>
    </LatestContentProvider>
    </HelmetProvider>
  );
};

export default App;
