import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const TeamItem = ({ item }) => {
  const [isActive, setIsActive] = useState(false);
  
  const cloudfrontUrl = process.env.REACT_APP_DATA_CLOUDFRONT_URL;
  const imageUrl = item?.images?.filename_disk
    ? `${cloudfrontUrl}${item.images.filename_disk}`
    : 'https://d1vkr02c9udppg.cloudfront.net/default-avatar.png';

  return (
    <div
      className="team-item fullscreen"
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
    >
      <img src={imageUrl} alt={item.name} className="fullscreen-media" />
      <div className="team-info">
        <div className={`team-text-cont ${isActive ? 'active' : ''}`}>
          <h2 className="text-lg font-semibold m-0">{item.name}</h2>
          <h5 className="text-md m-0">{item?.Job_title || 'Unknown Title'}</h5>
        </div>
        <Link to="/team">
          <span className="hover:underline">See our team</span>
        </Link>
      </div>
    </div>
  );
};

export default TeamItem;
