// src/pages/SearchList.js

import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import FetchData from '../components/FetchData';

const SearchList = () => {
  const [filteredData, setFilteredData] = useState([]);
  const location = useLocation();

  // Extract search term from the query parameter
  const searchParams = new URLSearchParams(location.search);
  const searchTerm = searchParams.get('search') || '';

  useEffect(() => {
    const handleSearch = async () => {
      try {
        const response = await FetchData();
        console.log('Fetched Data:', response); // Debugging line

        // Destructure the collections from the response
        const {
          posts = [],
          categories = [],
          people = [],
          awards = [],
          medals = [],
          pages = [],
          clients = [],
        } = response;

        // Function to perform case-insensitive search on relevant fields
        const searchInItem = (item) => {
          const lowerSearchTerm = searchTerm.toLowerCase();
          return (
            (item.name && item.name.toLowerCase().includes(lowerSearchTerm)) ||
            (item.title && item.title.toLowerCase().includes(lowerSearchTerm)) ||
            (item.description && item.description.toLowerCase().includes(lowerSearchTerm)) ||
            (item.tags &&
              Array.isArray(item.tags) &&
              item.tags.some(tag => tag.toLowerCase().includes(lowerSearchTerm)))
          );
        };

        // Filter each collection
        const filteredPosts = posts.filter(searchInItem);
        const filteredCategories = categories.filter(searchInItem);
        const filteredPeople = people.filter(searchInItem);
        const filteredAwards = awards.filter(searchInItem);
        const filteredMedals = medals.filter(searchInItem);
        const filteredPages = pages.filter(searchInItem);
        const filteredClients = clients.filter(searchInItem);

        // Combine all filtered results
        const combinedFilteredData = [
          ...filteredPosts.map(item => ({ ...item, type: 'Post' })),
          ...filteredCategories.map(item => ({ ...item, type: 'Category' })),
          ...filteredPeople.map(item => ({ ...item, type: 'Person' })),
          ...filteredAwards.map(item => ({ ...item, type: 'Award' })),
          ...filteredMedals.map(item => ({ ...item, type: 'Medal' })),
          ...filteredPages.map(item => ({ ...item, type: 'Page' })),
          ...filteredClients.map(item => ({ ...item, type: 'Client' })),
        ];

        setFilteredData(combinedFilteredData);
      } catch (error) {
        console.error('Error during search:', error);
        setFilteredData([]); // Optionally handle errors by setting an empty array
      }
    };

    if (searchTerm.trim()) {
      handleSearch();
    } else {
      setFilteredData([]); // Clear results if search term is empty
    }
  }, [searchTerm]);

  return (
    <div className="search-page pdc">
      <h1 className='mb-6'>Search Results "{searchTerm}"</h1>
      {filteredData.length > 0 ? (
        filteredData.map(item => (
          <div key={item.id} className="search-item">
            <h2>{item.title || item.name}</h2>
            <p dangerouslySetInnerHTML={{__html:item.short_text || item.description}} />
            {/* Handle different types to generate appropriate links */}
            {item.type === 'Post' && (
              <Link to={`/${item.category?.slug || 'uncategorized'}/${item.slug}`}>Go to Post</Link>
            )}
            {item.type === 'Person' && (
              <Link to={`/team/`}>Go to team</Link>
            )}
            {item.type === 'Award' && (
              <Link to={`/awards/${item.slug}`}>Go to Award</Link>
            )}
            {item.type === 'Medal' && (
              <Link to={`/awards`}>Go to all Awards page</Link>
            )}
            {item.type === 'Page' && (
              <Link to={`/${item.slug}`}>Go to Page</Link>
            )}
            {item.type === 'Client' && (
              <Link to={`/client`}>Go to Client</Link>
            )}
          </div>
        ))
      ) : (
        <div className="no-results">
          <p>No results found for "{searchTerm}".</p>
          <Link to="/">Back to Home</Link>
        </div>
      )}
    </div>
  );
};

export default SearchList;
