import React, { useState, useRef, useEffect, useCallback } from 'react';
import ContentItem from './ContentItem';
import TeamItem from './TeamItem';
import PageItem from './PageItem';
import AwardItem from './AwardItem';
import Sidebar from './Sidebar';

const ScrollContainer = ({ posts = [], awards = [], team = [], pages = [] }) => {
  const [displayData, setDisplayData] = useState([]);
  const [dataIndex, setDataIndex] = useState(0);
  const [isMuted, setIsMuted] = useState(true);
  const [hasVideo, setHasVideo] = useState(false); // Tracks if the .scroll-item.current has a video
  const itemsPerLoad = 10; // Number of items to load per scroll
  const containerRef = useRef(null);
  const itemsRef = useRef([]);

  // Function to combine data
  const combineData = useCallback(() => {
    return [
      ...(Array.isArray(posts) ? posts.map(item => ({ ...item, type: 'posts' })) : []),
      ...(Array.isArray(awards) ? awards.map(item => ({ ...item, type: 'awards' })) : []),
      ...(Array.isArray(team) ? team.map(item => ({ ...item, type: 'team' })) : []),
      ...(Array.isArray(pages) ? pages.map(item => ({ ...item, type: 'pages' })) : []),
    ];
  }, [posts, awards, team, pages]);

  // Initial load of data
  useEffect(() => {
    const combinedData = combineData();
    if (combinedData.length > 0 && displayData.length === 0) {
      setDisplayData(combinedData.slice(0, itemsPerLoad));
      setDataIndex(itemsPerLoad);
    }
  }, [combineData, displayData.length, itemsPerLoad]);

  // Detect if current .scroll-item.current contains a video
  useEffect(() => {
    const checkForVideo = () => {
      const currentItem = document.querySelector('.scroll-item.current'); // Select the current scroll item
      if (currentItem) {
        const videoElement = currentItem.querySelector('video'); // Search for any video element inside currentItem
        setHasVideo(!!videoElement); // Set hasVideo to true if video exists
      } else {
        setHasVideo(false); // No current scroll item, so no video
      }
    };

    checkForVideo(); // Initial check when component loads
    window.addEventListener('scroll', checkForVideo); // Recheck on scroll

    return () => {
      window.removeEventListener('scroll', checkForVideo); // Cleanup listener on unmount
    };
  }, [displayData]);

  // Load more items when scrolled to the bottom
  const handleScroll = useCallback(() => {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 100) {
      const combinedData = combineData();
      if (dataIndex < combinedData.length) {
        const nextData = combinedData.slice(dataIndex, dataIndex + itemsPerLoad);
        setDisplayData(prevData => [...prevData, ...nextData]);
        setDataIndex(prevIndex => prevIndex + itemsPerLoad);
      }
    }
  }, [combineData, dataIndex, itemsPerLoad]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const handleScrollUp = () => {
    const currentElement = document.querySelector('.scroll-item.current');
    if (currentElement && currentElement.previousElementSibling) {
      // Scroll to the previous sibling
      currentElement.previousElementSibling.scrollIntoView({ behavior: 'smooth' });
      currentElement.classList.remove('current');
      currentElement.previousElementSibling.classList.add('current');
    } else {
      console.log('Already at the first item.');
    }
  };

  const handleScrollDown = () => {
    const currentElement = document.querySelector('.scroll-item.current');
    if (currentElement && currentElement.nextElementSibling) {
      // Scroll to the next sibling
      currentElement.nextElementSibling.scrollIntoView({ behavior: 'smooth' });
      currentElement.classList.remove('current');
      currentElement.nextElementSibling.classList.add('current');
    } else {
      console.log('Already at the last item.');
    }
  };

  const handleMute = () => {
    setIsMuted(prevMuted => !prevMuted);
  };

  // Render content based on type
  const renderContent = (item) => {
    switch (item.type) {
      case 'posts':
        return <ContentItem key={item.id} item={item} isMuted={isMuted} onMute={handleMute} />;
      case 'awards':
        return <AwardItem key={item.id} item={item} isMuted={isMuted} onMute={handleMute} />;
      case 'team':
        return <TeamItem key={item.id} item={item} />;
      case 'pages':
        return <PageItem key={item.id} item={item} />;
      default:
        return null;
    }
  };

  return (
    <div className="scroll-container" ref={containerRef}>
      <Sidebar
        onScrollUp={handleScrollUp}
        onScrollDown={handleScrollDown}
        onMute={handleMute}
        isMuted={isMuted}
        hasVideo={hasVideo} // Dynamically show/hide the mute/unmute button based on whether there's a video
      />
      {displayData.map((item, index) => (
        <div
          key={item.id || index}
          ref={el => (itemsRef.current[index] = el)}
          className={`scroll-item ${index === 0 ? 'current' : ''}`}
        >
          {renderContent(item)}
        </div>
      ))}
      {/* Show a loading indicator or message if needed */}
      {dataIndex < combineData().length && <div className="loading-message">Loading more...</div>}
    </div>
  );
};

export default ScrollContainer;
