import axios from 'axios';

// Fetch site data from API using Directus or similar
const FetchSite = async () => {
  const siteDataUrl = process.env.REACT_APP_DATA_SITE_URL;
  const token = process.env.REACT_APP_DIRECTUS_API_TOKEN; 
  try {
    if (!siteDataUrl) {
      console.warn('No URL provided for the site data source.');
      return null;
    }

  
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    // Fetch data from the API
    const response = await axios.get(siteDataUrl, { headers });
    const fetchedData = response.data || {};

    
    return fetchedData;
  } catch (error) {
    console.error(`Error fetching site data from ${siteDataUrl}:`, error);
    return null;
  }
};

export default FetchSite;
