import React, { useContext, useState, useEffect } from 'react';
import { LatestContentContext } from '../components/LatestContentContext';
import ScrollContainer from '../components/ScrollContainer';

const Activities = () => {
  const { posts = [] } = useContext(LatestContentContext); // Only fetching posts since categories are directly within posts
  const [filteredPosts, setFilteredPosts] = useState([]);

  // Filter posts to show only those with categorySlug 'works'
  useEffect(() => {
    // Filter posts that have categorySlug 'works' and are published
    const workPosts = posts.filter(
      (post) => post.categorySlug === 'activities' && post.status === 'published'
    );

    if (workPosts.length > 0) {
      setFilteredPosts(workPosts);
    } else {
      console.warn('No posts with categorySlug "Activities" found.');
    }
  }, [posts]);

  return (
    <div className="work-page top-screen pdc">
      <div className="scroll-container">
        {filteredPosts.length > 0 ? (
          <ScrollContainer posts={filteredPosts} />
        ) : (
          <div>No content available.</div>
        )}
      </div>
    </div>
  );
};

export default Activities;
