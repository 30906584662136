import React, { useState, useEffect } from 'react';
import fetchData from '../components/FetchData';
import AwardList from '../components/AwardList';
import AwardsCarousel from '../components/AwardsCarousel';
import Sidebar from '../components/Sidebar';

const Awards = () => {
  const [awardData, setAwardData] = useState([]);
  const [medalData, setMedalData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [viewMode, setViewMode] = useState('post'); // Track the current view mode

  useEffect(() => {
    const loadAwardAndMedalData = async () => {
      setLoading(true);
      try {
        const data = await fetchData(); 
        const { awards, medals } = data;

        if (awards && medals) {
          // console.log("Awards Data:", awards);
          // console.log("Medals Data:", medals);
          setAwardData(awards); 
          setMedalData(medals);
        } else {
          console.error('No awards or medals data found');
        }
      } catch (error) {
        console.error('Failed to load award and medal data:', error);
      } finally {
        setLoading(false);
      }
    };

    loadAwardAndMedalData();
  }, []);

  // Function to toggle between list view and carousel view
  const toggleViewMode = () => {
    setViewMode((prevMode) => (prevMode === 'post' ? 'carousel' : 'post')); 
  };

  if (loading) {
    return <div>Loading award and medal data...</div>;
  }

  return (
    <div className='award-page'>
      <Sidebar
        viewMode={viewMode}
        toggleViewMode={toggleViewMode} // Pass the toggle function to Sidebar
      />
      {viewMode === 'carousel' ? (
        <AwardList medals={medalData} /> // Render list view (medals)
      ) : (
        <AwardsCarousel awards={awardData} /> // Render carousel view (awards)
      )}
    </div>
  );
};

export default Awards;
