import React, { useEffect, useState } from 'react';
import FetchData from '../components/FetchData'; 
import FetchSite from '../components/FetchSite';
import emailjs from 'emailjs-com'; // Import EmailJS

const cloudfrontUrl = process.env.REACT_APP_DATA_CLOUDFRONT_URL;

const Contact = () => {
  const [content, setContent] = useState(null);
  const [socialLinks, setSocialLinks] = useState({});
  const [contactInfo, setContactInfo] = useState([]);
  const [videoSrc, setVideoSrc] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false); // Track form submission status
  const [isProcessing, setIsProcessing] = useState(false); // Track if the email is being sent

  // Get EmailJS credentials from .env
  const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
  const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const result = await FetchData();
        const dataSite = await FetchSite();
  
        const pages = result.pages; 
        const contactContent = pages.find(item => item.slug === 'contact'); 
        setContent(contactContent);
  
        if (dataSite && dataSite.data) {
          const siteData = dataSite.data; 
          
          if (siteData.sosmed) {
            const socialMedia = siteData.sosmed.reduce((acc, item) => {
              acc[item.item.platform.toLowerCase()] = item.item.profile_url;
              return acc;
            }, {});
            
            setSocialLinks(socialMedia);
          } else {
            console.error('No social media links found in siteData');
          }
  
          if (siteData.contact) {
            setContactInfo(siteData.contact);
          } else {
            console.error('No contact information found in siteData');
          }
  
          const videoData = contactContent?.video;
          if (videoData && videoData.filename_disk) {
            const videoFilename = videoData.filename_disk;
            setVideoSrc(`${cloudfrontUrl.replace(/\/+$/, '')}/${videoFilename}`);
          } else {
            console.error('No video data found in contactContent');
          }
        } else {
          console.error('No valid data found in dataSite');
        }
      } catch (error) {
        console.error('Error fetching contact content or site data:', error);
      }
    };
  
    fetchContent();
  }, []);

  // Function to open the modal when "Download Credentials" is clicked
  const handleDownloadClick = () => {
    setShowModal(true);
  };
  
  // Function to handle email submit with EmailJS integration
  const handleEmailSubmit = (e) => {
    e.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(userEmail)) {
      setIsProcessing(true); // Set to true when processing starts

      const templateParams = {
        user_email: userEmail,
        message: `Please send the credential to this email: ${userEmail}`,
      };

      emailjs
        .send(serviceId, templateId, templateParams, publicKey)
        .then(
          (result) => {
            // console.log('Email successfully sent!', result.text);
            setIsSubmitted(true); // Set to true when submission is successful
            setIsProcessing(false); // Stop processing
          },
          (error) => {
            console.error('Failed to send email:', error.text);
            setIsProcessing(false); // Stop processing if there's an error
          }
        );
    } else {
      setEmailError('Please enter a valid email address.');
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEmailError(''); // Reset email error on modal close
    setIsSubmitted(false); // Reset submission status when modal is closed
    setIsProcessing(false); // Reset processing status
    setUserEmail(''); // Clear the email input
  };

  // Close modal if clicked outside of the modal content
  const handleOutsideClick = (e) => {
    if (e.target.classList.contains('modal-overlay')) {
      handleCloseModal();
    }
  };

  if (!content) return <div>Loading...</div>;
  if (!content.title) return <div>No content found</div>;

  return (
    <div className="page-container contact-page">
      {/* Video Container */}
      <div className='video-fullscreen-cont'>
        <video 
          src={videoSrc} 
          autoPlay
          muted={true}
          controls={false}
          preload="auto"
          loop
          playsInline
          disablePictureInPicture
          controlsList="nodownload"
        />
      </div>

      {/* Social Media Section */}
      <div className='social-media-cont'>
        <ul className='address-cont mb-4'>
          {contactInfo.map((contact, index) => (
            index === 0 && (
              <li key={index}>
                <h4 className='m-0 mb-1'>{contact.item.title}</h4>
                {contact.item.phone} | <a href={`mailto:${contact.item.email}`}>{contact.item.email}</a>
              </li>
            )
          ))}
        </ul>
        <div className="social-media mb-4">
          <ul className='socmed-cont'>
            <li>
              <a href={socialLinks.linkedin || '#'} target="_blank" rel="noopener noreferrer">
                <i className="fa-brands fa-linkedin"></i> LinkedIn
              </a>
            </li>
            <li>
              <a href={socialLinks.facebook || '#'} target="_blank" rel="noopener noreferrer">
                <i className="fa-brands fa-facebook-square"></i> Facebook
              </a>
            </li>
            <li>
              <a href={socialLinks.instagram || '#'} target="_blank" rel="noopener noreferrer">
                <i className="fa-brands fa-instagram"></i> Instagram
              </a>
            </li>
            <li>
              <a href={socialLinks.youtube || '#'} target="_blank" rel="noopener noreferrer">
                <i className="fa-brands fa-youtube"></i> YouTube
              </a>
            </li>
            <li>
              <a href={socialLinks.twitter || '#'} target="_blank" rel="noopener noreferrer">
                <i className="fa-brands fa-square-x-twitter"></i> X/Twitter
              </a>
            </li>
            <li>
              <a href={socialLinks.whatsapp || '#'} target="_blank" rel="noopener noreferrer">
                <i className="fa-brands fa-whatsapp"></i> WhatsApp
              </a>
            </li>
          </ul>
        </div>

        {/* Download Credentials Button */}
        <button className='dl-credential' onClick={handleDownloadClick}>
          Click to Download Credentials
        </button>
      </div>

      {/* Modal Popup */}
      {showModal && (
        <div className="modal-overlay" onClick={handleOutsideClick}>
          <div className="modal-content">
            <button className="close-btn" onClick={handleCloseModal}>X</button>
            <h3>{isSubmitted ? 'Submit Success!' : 'Enter your email to request credentials'}</h3>

            {/* Show form only if not submitted */}
            {!isSubmitted && (
              <form onSubmit={handleEmailSubmit}>
                <input 
                  type="email" 
                  placeholder="Your Email" 
                  value={userEmail}
                  onChange={(e) => {
                    setUserEmail(e.target.value);
                    setEmailError(''); // Clear error when user starts typing
                  }} 
                  required
                  disabled={isProcessing} // Disable input while processing
                />
                {emailError && <p className='m-0 mb-2 text-sm' style={{ color: 'red' }}>{emailError}</p>}
                <button className='submit-btn' type="submit" disabled={isProcessing}>
                  {isProcessing ? 'Processing...' : 'Submit'}
                </button>
              </form>
            )}

            {/* Success message */}
            {isSubmitted && <p>Thank you! Your request has been sent.</p>}
          </div>
        </div>
      )}
    </div>
  );
};

export default Contact;
