import React, { useEffect, useState } from 'react';
import FetchSite from './FetchSite';

const Social = () => {
  const [socialLinks, setSocialLinks] = useState({}); // State to store social media links

  useEffect(() => {
    const fetchSocialLinks = async () => {
      try {
        const dataSite = await FetchSite(); // Fetch site information
        // console.log('Fetched Site Data:', dataSite); // Debugging: Log site data

        // Ensure dataSite contains the expected structure
        if (dataSite && dataSite.data && dataSite.data.sosmed) {
          const socialMedia = dataSite.data.sosmed.reduce((acc, item) => {
            acc[item.item.platform.toLowerCase()] = item.item.profile_url;
            return acc;
          }, {});
          setSocialLinks(socialMedia); // Set social media links from the fetched data
        } else {
          console.error('No social media links found');
        }
      } catch (error) {
        console.error('Error fetching social media links:', error);
      }
    };

    fetchSocialLinks();
  }, []);

  return (
    <ul className="navbar-social-links">
      <li>
        <a href={socialLinks.linkedin || '#'} target="_blank" rel="noopener noreferrer">
          <i className="fa-brands fa-linkedin"></i> LinkedIn
        </a>
      </li>
      <li>
        <a href={socialLinks.facebook || '#'} target="_blank" rel="noopener noreferrer">
          <i className="fa-brands fa-facebook-square"></i> Facebook
        </a>
      </li>
      <li>
        <a href={socialLinks.instagram || '#'} target="_blank" rel="noopener noreferrer">
          <i className="fa-brands fa-instagram"></i> Instagram
        </a>
      </li>
      <li>
        <a href={socialLinks.youtube || '#'} target="_blank" rel="noopener noreferrer">
          <i className="fa-brands fa-youtube"></i> YouTube
        </a>
      </li>
      <li>
        <a href={socialLinks.twitter || '#'} target="_blank" rel="noopener noreferrer">
          <i className="fa-brands fa-square-x-twitter"></i> X/Twitter
        </a>
      </li>
      <li>
        <a href={socialLinks.whatsapp || '#'} target="_blank" rel="noopener noreferrer">
          <i className="fa-brands fa-whatsapp"></i> WhatsApp
        </a>
      </li>
    </ul>
  );
};

export default Social;
