import React, { useContext, useState, useEffect } from 'react';
import { LatestContentContext } from '../components/LatestContentContext';
import ScrollContainer from '../components/ScrollContainer';

const WorkList = () => {
  const { posts = [] } = useContext(LatestContentContext);
  const [filteredPosts, setFilteredPosts] = useState([]);

  useEffect(() => {
    console.log('Original Posts:', posts);

    // Filter posts by category slug 'works' and status 'published'
    const workPosts = posts.filter(
      (post) => post.category?.slug === 'works' && post.status === 'published'
    );

    console.log('Filtered Posts (before sorting):', workPosts);

    // Ensure the correct field is being used for sorting
    const sortedPosts = workPosts.sort((a, b) => {
      const dateA = new Date(a.date_created); // Replace with the correct field if necessary
      const dateB = new Date(b.date_created); // Replace with the correct field if necessary
      return dateB - dateA; // Sort in descending order
    });

    console.log('Sorted Filtered Posts:', sortedPosts);
    
    // Set filtered posts to state
    setFilteredPosts(sortedPosts);
  }, [posts]);

  return (
    <div className="work-page top-screen pdc">
      <div className="scroll-container">
        {/* Pass all filtered posts to ScrollContainer */}
        <ScrollContainer posts={filteredPosts} />
      </div>
    </div>
  );
};

export default WorkList;
