import React, { useEffect, useState } from 'react';
import fetchData from '../components/FetchData'; // Adjust the import path as needed

const DataDisplayComponent = () => {
  const [data, setData] = useState([]); // State to hold fetched data
  const [error, setError] = useState(null); // State to handle errors

  useEffect(() => {
    // Fetch data when the component mounts
    const fetchDataFromAPI = async () => {
      try {
        const fetchedData = await fetchData();
        console.log('Fetched Data:', fetchedData); // Log the fetched data
        setData(fetchedData); // Set the fetched data to state
      } catch (error) {
        console.error('Error fetching data:', error); // Log any errors
        setError(error);
      }
    };

    fetchDataFromAPI();
  }, []); // Empty dependency array means this runs once when the component mounts

  if (error) {
    return <div>Error fetching data: {error.message}</div>;
  }

  return (
    <div>
      <h1>Fetched Data</h1>
      {/* Render the data */}
      {data.length > 0 ? (
        <ul>
          {data.map((item, index) => (
            <li key={index}>{JSON.stringify(item)}</li> // Adjust rendering as needed
          ))}
        </ul>
      ) : (
        <p>No data available.</p>
      )}
    </div>
  );
};

export default DataDisplayComponent;
