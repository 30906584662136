// src/components/RightSidebar.js
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { LatestContentContext } from './LatestContentContext';
import Social from './Social';

const RightSidebar = () => {
  const { posts = [] } = useContext(LatestContentContext);

  // Limit to the first 5 items
  const latestContent = posts.slice(0, 5);

  return (
    <div className="right-sidebar">
      <div className="latest-content">
        <h3><Link to="/latest"> Latest Content</Link></h3>
        <ul>
          {latestContent.map((item, index) => (
            <li key={item.id || `${item.date}-${index}`}>
              <Link
                to={`/${item.category?.slug?.toLowerCase() || 'uncategorized'}/${item.slug}`}
                className="single-line-ellipsis"
                title={item.title}
              >
                {item.title || 'Untitled'}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="contact-info">
        <h3 className='m-0'>Narrada Communications</h3>
        <p className='m-0'>+62 21 2751 4833</p>
        <p className='m-0'><a href="mailto:contact@narrada.com">contact@narrada.com</a></p>
        <br />
        <Social />
        <div className="partner-cont">
          <div className="partner-text">
            <p>
            We partner with the best to enhance our services for you.
            We are now qualified as a Google Partner and Meta Business Partner.
            </p>
          </div>
          <div className="partner-logos">
            <a href="https://www.facebook.com/business/partner-directory/search?solution_type=campaign_management&ref=fmp_nav_bar&countries=ID&id=4560953363921050&section=overview" target="_blank" rel="noreferrer"><img src="/assets/img/meta-partner-logo.png" alt="Meta Business Partner" className="partner-logo" /></a>
            <a href="https://www.google.com/partners/agency?id=4894937497" target="_blank" rel="noreferrer"><img src="/assets/img/google-partner-logo.jpg" alt="Google Partner" className="partner-logo" /></a>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default RightSidebar;
