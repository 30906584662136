import React, { useState, useEffect } from 'react';
import fetchData from '../components/FetchData'; // Import the fetchData function
import ScrollContainer from '../components/ScrollContainer';
import ContentItem from '../components/ContentItem';
import PageItem from '../components/PageItem';

const Feed = () => {
  const [content, setContent] = useState([]);
  const [headerContent, setHeaderContent] = useState(null);

  useEffect(() => {
    // Fetch data when the component mounts
    const fetchContent = async () => {
      const data = await fetchData(); // Fetch data asynchronously

      if (data) {
        // Filter to include only published content
        const publishedContent = data.filter(item => item.status === 'published');

        // Find the header content
        const header = publishedContent.find(item => item.is_header);
        setHeaderContent(header);

        // Generate initial random content excluding the header
        const initialContent = generateRandomContent(publishedContent, 5); // Start with 5 items
        setContent([header, ...initialContent]); // Include header content at the top
      }
    };

    fetchContent();
  }, []);

  const generateRandomContent = (data, count) => {
    const shuffledContent = data
      .filter(item => !item.is_header) // Filter out the header item
      .sort(() => Math.random() - 0.5) // Shuffle the content randomly
      .slice(0, count); // Select 'count' random items

    return shuffledContent;
  };

  const loadMoreContent = () => {
    const newContent = generateRandomContent(content, 2); // Generate 2 more items
    setContent(prevContent => [...prevContent, ...newContent]); // Append new items to existing content
  };

  const renderItem = (item) => {
    if (item.category === 'work' || item.category === 'activities') {
      return <ContentItem key={item.id} item={item} isMuted={true} />;
    } else {
      return <PageItem key={item.id} item={item} />;
    }
  };

  return (
    <div className="feed-page top-screen pdc">
      {headerContent && (
        <ScrollContainer content={content} onLoadMore={loadMoreContent}>
          {content.map((item) => renderItem(item))}
        </ScrollContainer>
      )}
    </div>
  );
};

export default Feed;
