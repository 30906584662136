import React, { useEffect, useState } from 'react';
import FetchData from '../components/FetchData'; // Adjust the path if necessary

const About = () => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const data = await FetchData(); // Fetch all data from your FetchData.js
        const aboutContent = data.pages.find(item => item.slug === 'about'); // Find the page with the slug 'about'
        
        if (aboutContent) {
          setContent(aboutContent);
        } else {
          console.error('No about page found.');
        }
      } catch (error) {
        console.error('Error fetching about content:', error);
      }
    };

    fetchContent();
  }, []);

  if (!content) return <div>Loading...</div>;

  return (
    <div className="page-container">
      <div className="headbanner img-cont mb-5">
        {/* Display the image for the about page */}
        {content.images && content.images.length > 0 && (
          <img
            src={`https://d1vkr02c9udppg.cloudfront.net/${content.images[0]?.directus_files_id?.filename_disk}`} 
            alt={content.title}
          />
        )}
      </div>
      
      <div className="content-page">
      <h2 className="title mb-4">{content.title}</h2>
        {/* Render the long text using dangerouslySetInnerHTML */}
        <p className="text-paragraph mb-5" dangerouslySetInnerHTML={{ __html: content.description }} />
        <div className="social-media lg:hidden">
          {/* Twitter */}
          <a href={`https://twitter.com/intent/tweet?url=${window.location.href}`} target="_blank" rel="noopener noreferrer">
            <i className="fa-brands fa-x-twitter"></i>
          </a>
          {/* Facebook */}
          <a href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`} target="_blank" rel="noopener noreferrer">
            <i className="fa-brands fa-facebook-square"></i>
          </a>
          {/* LinkedIn */}
          <a href={`https://www.linkedin.com/shareArticle?url=${window.location.href}`} target="_blank" rel="noopener noreferrer">
            <i className="fa-brands fa-linkedin"></i> 
          </a>
          {/* YouTube */}
          {content.youtube_url && (
            <a href={content.youtube_url} target="_blank" rel="noopener noreferrer">
              <i className="fa-brands fa-youtube"></i>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default About;
