import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import unmuteIcon from '../assets/img/unmute-icon.png';
import muteIcon from '../assets/img/mute-icon.png';
import homeIcon from '../assets/img/home-icon.png';
import latestIcon from '../assets/img/latest-icon.png';
import topArrowIcon from '../assets/img/top-arrow.png';
import downArrowIcon from '../assets/img/down-arrow.png';
import shareIcon from '../assets/img/share-icon.png';
import AwardIcon from '../assets/img/award-icon.png';

const Sidebar = ({ onScrollUp, onScrollDown, onMute, isMuted, viewMode, toggleViewMode }) => {
  const [hasVideoInView, setHasVideoInView] = useState(false); // State to track visibility of video
  const location = useLocation();
  const isAwardPage = location.pathname === '/awards';

  const currentUrl = window.location.href;

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: document.title,
          text: 'Check this out!',
          url: currentUrl,
        });
        console.log('Shared successfully');
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      console.log('Web Share API is not supported in this browser.');
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const scrollToFirstContentItem = () => {
    const firstContentItem = document.querySelector('.content-item');
    if (firstContentItem) {
      firstContentItem.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    let observer = null;
    let mutationObserver = null;

    const handleIntersection = (entries) => {
      let videoInView = false;

      entries.forEach((entry) => {
        const element = entry.target;
        if (element.tagName === 'VIDEO') {
          if (entry.isIntersecting && entry.intersectionRatio === 1) {
            videoInView = true;
          }
        }
      });

      setHasVideoInView(videoInView);
    };

    const observeVideos = () => {
      const videoElements = document.querySelectorAll('video'); // Observe all videos
      if (videoElements.length === 0) {
        // No videos to observe
      } else {
        videoElements.forEach((video) => {
          observer.observe(video);
        });
      }
    };

    // Initialize IntersectionObserver
    observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: '0px',
      threshold: 1.0, // Trigger when 100% of the video is in view
    });

    // Start observing existing videos
    observeVideos();

    // Initialize MutationObserver to detect when new videos are added to the DOM
    mutationObserver = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
          mutation.addedNodes.forEach((node) => {
            if (node.nodeType === 1) {
              const videos = node.querySelectorAll && node.querySelectorAll('video');
              if (videos && videos.length > 0) {
                videos.forEach((video) => {
                  observer.observe(video);
                });
              }
            }
          });
        }
      });
    });

    // Start observing changes in the entire document body
    mutationObserver.observe(document.body, {
      childList: true,
      subtree: true, // Watch the entire document tree
    });

    // Retry video observation in case videos load later
    const retryObserver = setInterval(() => {
      observeVideos();
    }, 2000); // Retry every 2 seconds

    // Stop retrying after a certain amount of time
    setTimeout(() => {
      clearInterval(retryObserver);
    }, 30000); // Stop retrying after 30 seconds

    // Cleanup on unmount
    return () => {
      if (observer) {
        observer.disconnect();
      }
      if (mutationObserver) {
        mutationObserver.disconnect();
      }
      clearInterval(retryObserver);
    };
  }, []);

  return (
    <div className="sidebar">
      {/* Mute/Unmute Button */}
      {hasVideoInView && (
        <button
          onClick={onMute}
          className="sidebar-button"
          aria-label={isMuted ? 'Unmute videos' : 'Mute videos'}
        >
          <img src={isMuted ? muteIcon : unmuteIcon} alt="Mute/Unmute" />
          <span>{isMuted ? ' ' : ' '}</span>
        </button>
      )}

      {/* Home Button */}
      {location.pathname !== '/' && (
        <Link to="/" className="sidebar-button" onClick={scrollToTop}>
          <img src={homeIcon} alt="Home" />
        </Link>
      )}

      {location.pathname === '/' && (
        <button className="sidebar-button" onClick={scrollToFirstContentItem}>
          <img src={homeIcon} alt="Top" />
        </button>
      )}

      {/* Latest Content Button */}
      {!isAwardPage && (
        <Link to="/latest" className="sidebar-button">
          <img src={latestIcon} alt="Latest Content" />
        </Link>
      )}

      {/* Toggle between List View and Post View, only on /awards */}
      {isAwardPage && (
        <button
          onClick={() => toggleViewMode(viewMode === 'postview' ? 'listview' : 'postview')}
          className="sidebar-button"
          aria-label="Toggle View Mode"
        >
          <img src={AwardIcon} alt="Toggle View" />
          {/* <span>{viewMode === 'postview' ? 'List' : 'Post'}</span> */}
        </button>
      )}

      {/* Share Button */}
      <button className="sidebar-button" onClick={handleShare} aria-label="Share">
        <img src={shareIcon} alt="Share" />
      </button>

      {!isAwardPage && (
        <button onClick={onScrollUp} className="sidebar-button" aria-label="Scroll Up">
          <img src={topArrowIcon} alt="Up" />
        </button>
      )}
      {!isAwardPage && (
        <button onClick={onScrollDown} className="sidebar-button" aria-label="Scroll Down">
          <img src={downArrowIcon} alt="Down" />
        </button>
      )}
    </div>
  );
};

export default Sidebar;
