import React, { createContext, useState, useEffect } from 'react';
import fetchData from '../components/FetchData'; // Make sure fetchData is correctly imported

export const LatestContentContext = createContext();

export const LatestContentProvider = ({ children }) => {
  const [posts, setPosts] = useState([]);
  const [awards, setAwards] = useState([]);
  const [team, setTeam] = useState([]);
  const [pages, setPages] = useState([]);

  useEffect(() => {
    const loadContent = async () => {
      try {
        const data = await fetchData(); // Fetch all data using the fetchData function
        setPosts(data.posts || []);
        setAwards(data.awards || []);
        setTeam(data.people || []);
        setPages(data.pages || []);
      } catch (error) {
        console.error('Failed to fetch latest content:', error);
      }
    };

    loadContent();
  }, []);

  return (
    <LatestContentContext.Provider value={{ posts, awards, team, pages }}>
      {children}
    </LatestContentContext.Provider>
  );
};
