import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import fetchData from '../components/FetchData';
import Sidebar2 from '../components/Sidebar2';
import Helmet from '../components/Helmet';
import ArrowLeft from '../assets/img/arrow-icon-left.png';
import ArrowRight from '../assets/img/arrow-icon-right.png';

const ContentDetail = () => {
  const { category, title } = useParams();
  const [item, setItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const startX = useRef(0);

  const cloudfrontUrl = process.env.REACT_APP_DATA_CLOUDFRONT_URL;

  useEffect(() => {
    const formatString = (str) => {
      return str
        .toLowerCase()
        .replace(/[^a-z0-9-_]+/g, '-') // Replace unwanted characters with dashes
        .replace(/^-+|-+$/g, ''); // Trim leading or trailing dashes
    };

    const formattedCategory = formatString(category);
    const formattedTitle = formatString(title);

    const fetchItem = async () => {
      try {
        const data = await fetchData(formattedCategory, formattedTitle);
        const post = data.posts.find(
          (post) =>
            formatString(post.categorySlug || 'uncategorized') === formattedCategory &&
            post.slug === formattedTitle
        );
        setItem(post);
      } catch (error) {
        console.error('Error fetching item:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchItem();
  }, [category, title]);

  const handlePrev = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? item.images.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === item.images.length - 1 ? 0 : prevIndex + 1));
  };

  const handleTouchStart = (e) => {
    startX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = (e) => {
    const endX = e.changedTouches[0].clientX;
    if (startX.current - endX > 50) {
      handleNext();
    } else if (endX - startX.current > 50) {
      handlePrev();
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!item) {
    return <div>Content not found</div>;
  }

  const getEmbedYoutubeUrl = (url) => {
    const urlParams = new URLSearchParams(new URL(url).search);
    const videoId = urlParams.get('v');
    return `https://www.youtube.com/embed/${videoId}`;
  };

  const embedYoutubeUrl = item.youtube_url ? getEmbedYoutubeUrl(item.youtube_url) : null;
  const hasCampaignData = item.campaign_logo?.filename_disk && item.campaign_name?.trim();
  const hasValidVideo = item.video?.filename_disk;
  const metaKeywords = item?.tags?.length ? item.tags.join(', ') : 'default, keywords';

  let mediaContent;

  if (embedYoutubeUrl) {
    mediaContent = (
      <div className='yt-cont'>
        <iframe
          src={embedYoutubeUrl}
          title={item.title}
          className="fullscreen-media"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </div>
    );
  } else if (hasValidVideo) {
    mediaContent = (
      <div className='vid-cont'>
        <video
          src={`${cloudfrontUrl}${item.video.filename_disk}`} // Use CloudFront URL for video
          controls
          disablePictureInPicture
          controlsList="nodownload"
          className="fullscreen-media"
        />
      </div>
    );
  } else if (item.images && item.images.length > 0) {
    // Render carousel for images
    mediaContent = (
      <div
        className='carousel image-cont for__detail-content'
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        <button className="carousel-control prev" onClick={handlePrev}>
        <img src={ArrowLeft} alt="left arrow"/>
        </button>
        <div className="carousel-content" style={{ transform: `translateX(-${currentImageIndex * 100}%)` }}>
          {item.images.map((image, index) => (
            <div className="carousel-item" key={index}>
              <img
                src={`${cloudfrontUrl}${image.directus_files_id.filename_disk}`} // Use CloudFront URL for images
                alt={item.title}
                className="fullscreen-media"
              />
              <p className="image-title">{image.directus_files_id.title}</p>
            </div>
          ))}
        </div>
        <button className="carousel-control next" onClick={handleNext}>
        <img src={ArrowRight} alt="right arrow"/>
        </button>
        <div className="carousel-dots">
          {item.images.map((_, index) => (
            <span
              key={index}
              className={`dot ${index === currentImageIndex ? 'active' : ''}`}
              onClick={() => setCurrentImageIndex(index)}
            ></span>
          ))}
        </div>
      </div>
    );
  } else {
    mediaContent = <div>No media available</div>;
  }

  const tags = item.tags && item.tags.length > 0 ? (
    <ul className='tags-list'>
      {item.tags.map((tag, index) => (
        <li key={index}>
          <Link to={`/search?search=${encodeURIComponent(tag)}`}>
            {tag}
          </Link>
        </li>
      ))}
    </ul>
  ) : (
    <p> </p> //for no tag available
  );

  return (
    <div class="content-detail-page pb-36 mt-20 sm:mt-0 lg:mt-10">
      {mediaContent}
      <div className="content-detail-info pt-6 ps-6 pr-20 lg:ps-0 lg:pr-0">
        {hasCampaignData && (
          <div className="client-tag">
            <div className="client-logo">
              <img
                className={`${item.campaign_logo.filename_disk}-logo`}
                src={`${cloudfrontUrl}${item.campaign_logo.filename_disk}`} 
                alt={`${item.campaign_name} logo`}
              />
            </div>
            <span>{item.campaign_name}</span>
          </div>
        )}
        <h2 className='mb-2'>{item.title}</h2>
        <div className="text-cont" dangerouslySetInnerHTML={{ __html: item.long_text }} ></div>
        <div className='tag-cont'>
          {tags}
        </div>
      </div>
      <Sidebar2 />
      <Helmet
        title={`${item?.title || 'Default Title'} | Narrada Communication`}
        description={item?.short_text || 'Default description'}
        ogImage={item?.campaign_logo?.filename_disk ? `${cloudfrontUrl}${item.campaign_logo.filename_disk}` : 'https://your-default-image-url.com/default-image.png'}
        ogUrl={window.location.href} // or any other dynamic URL
        keyword={metaKeywords}
      />
    </div>
  );
};

export default ContentDetail;
