import React, { useContext, useState, useEffect, useCallback, useRef } from 'react';
import { LatestContentContext } from '../components/LatestContentContext';
import ContentItem from '../components/ContentItem';
import AwardItem from '../components/AwardItem';
// import TeamItem from '../components/TeamItem';
import PageItem from '../components/PageItem';
import Sidebar from '../components/Sidebar';
import SiteMeta from '../components/SiteMeta';

const Home = () => {
  const { posts = [], awards = [], team = [], pages = [] } = useContext(LatestContentContext);
  const [isMuted, setIsMuted] = useState(true);
  const scrollItemRefs = useRef([]);
  const [shuffledItems, setShuffledItems] = useState([]); // Store shuffled items
  const [visibleItems, setVisibleItems] = useState([]); // Store items to be displayed
  const [itemCount, setItemCount] = useState(0); // Track how many items have been displayed

  // Function to shuffle items but avoid consecutive duplicates
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  // Combine items and shuffle, filtering out any item with slug "client"
  useEffect(() => {
    const headerItem = posts.find((post) => post.is_header) || null; // Find the header item
    const nonHeaderItems = [
      ...posts.filter(post => !post.is_header && post.slug !== 'client').map(item => ({ ...item, type: 'posts' })),
      ...awards.filter(award => award.slug !== 'client').map(item => ({ ...item, type: 'awards' })),
      ...team.filter(member => member.slug !== 'client').map(item => ({ ...item, type: 'team' })),
      ...pages.filter(page => page.slug !== 'client').map(item => ({ ...item, type: 'pages' })),
    ];

    // Shuffle the non-header items
    const shuffledNonHeaderItems = shuffleArray(nonHeaderItems);

    // Combine the header and shuffled non-header items
    const combinedItems = headerItem ? [{ ...headerItem, type: 'posts' }, ...shuffledNonHeaderItems] : shuffledNonHeaderItems;

    setShuffledItems(combinedItems); // Set shuffled items
    setVisibleItems(combinedItems.slice(0, 5)); // Show the first 5 items initially
    setItemCount(5); // Set initial visible item count to 5
  }, [posts, awards, team, pages]);

  const generateMoreItems = useCallback(() => {
    // Avoid generating items if shuffledItems is empty (which shouldn't happen)
    if (shuffledItems.length === 0) return;
  
    // Generate two more items from the shuffled list
    const newItems = [];
    for (let i = 0; i < 2; i++) {
      const index = (itemCount + i) % shuffledItems.length; // Loop through the data
      newItems.push(shuffledItems[index]);
    }
  
    // Ensure no duplicates between the last item and the first new item
    if (visibleItems[visibleItems.length - 1]?.id === newItems[0]?.id) {
      newItems.reverse(); // Swap the two new items to prevent duplicates in sequence
    }
  
    // Append new items to the visible list
    setVisibleItems((prev) => [...prev, ...newItems]);
    setItemCount((prevCount) => prevCount + 2); // Update the item count
  }, [shuffledItems, itemCount, visibleItems, setVisibleItems, setItemCount]); // Dependencies for generateMoreItems
  
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && entry.target.classList.contains('scroll-item')) {
            entry.target.classList.add('current'); // Add the "current" class
  
            if (entry.target === scrollItemRefs.current[scrollItemRefs.current.length - 1]) {
              generateMoreItems(); // Load more items when the last one comes into view
            }
  
            // Load media when it comes into view
            const mediaElement = entry.target.querySelector('video, img'); // Adjust this if necessary
            if (mediaElement) {
              const dataSrc = mediaElement.getAttribute('data-src');
              if (dataSrc) {
                mediaElement.src = dataSrc; // Set the source for the video or image
                mediaElement.removeAttribute('data-src'); // Remove the attribute once loaded
              }
            }
          } else {
            entry.target.classList.remove('current'); // Remove "current" class if not in view
          }
        });
      },
      { threshold: 0.1 }
    );
  
    const currentScrollItemRefs = scrollItemRefs.current; // Capture current refs in a local variable
  
    // Observe all current items
    currentScrollItemRefs.forEach((ref) => {
      if (ref) observer.observe(ref);
    });
  
    // Cleanup observer
    return () => {
      currentScrollItemRefs.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, [visibleItems, generateMoreItems]); // Add dependencies if they are necessary

  const handleMute = () => {
    setIsMuted((prev) => !prev);
  };

  const handleScrollUp = () => {
    const currentElement = document.querySelector('.scroll-item.current');
    
    if (currentElement && currentElement.previousElementSibling) {
      currentElement.previousElementSibling.scrollIntoView({ behavior: 'smooth' });
      currentElement.classList.remove('current');
      currentElement.previousElementSibling.classList.add('current');
    } else {
      console.log('Already at the first item.');
    }
  };

  const handleScrollDown = () => {
    const currentElement = document.querySelector('.scroll-item.current');
    
    if (currentElement && currentElement.nextElementSibling) {
      currentElement.nextElementSibling.scrollIntoView({ behavior: 'smooth' });
      currentElement.classList.remove('current');
      currentElement.nextElementSibling.classList.add('current');
    } else {
      console.log('Already at the last item.');
    }
  };

  const renderContent = (item) => {
    if (!item) return null;

    // Render the normal content item
    switch (item.type) {
      case 'posts':
        return <ContentItem key={item.id} item={item} isMuted={isMuted} onMute={handleMute} />;
      case 'awards':
        return <AwardItem key={item.id} item={item} isMuted={isMuted} onMute={handleMute} />;
      // case 'team':
      //   return <TeamItem key={item.id} item={item} />;
      case 'pages':
        return <PageItem key={item.id} item={item} />;
      default:
        return null;
    }
  };

  return (
    <>
      <SiteMeta />
      <div className="home-page top-screen pdc">
        <div className="scroll-container" style={{ minHeight: '100vh' }}>
          <Sidebar
            onScrollUp={handleScrollUp}
            onScrollDown={handleScrollDown}
            onMute={handleMute}
            isMuted={isMuted}
          />
          {visibleItems.map((item, index) => (
            <div
              className="scroll-item"
              key={`${item.id}-[${index}]`} // Use item.id and index for a unique key
              ref={(el) => (scrollItemRefs.current[index] = el)}
            >
              {renderContent(item)}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Home;
