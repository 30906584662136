import React from 'react';
import { Link } from 'react-router-dom';

// Helper function to construct media URLs from Directus file IDs
const getMediaUrl = (imageArray) => {
  if (imageArray && imageArray.length > 0) {
    const imageFile = imageArray[0].directus_files_id;
    const cloudFrontUrl = process.env.REACT_APP_DATA_CLOUDFRONT_URL; // Access CloudFront URL from .env
    return imageFile ? `${cloudFrontUrl}${imageFile.filename_disk}` : null;
  }
  return null;
};

const PageItem = ({ item }) => {
  // Construct image and video URLs from Directus data
  const imageUrl = getMediaUrl(item.images); // Adjusted to extract from images array
  const videoUrl = getMediaUrl(item.video); // Assuming the same structure for videos
  const pageLink = `/${item.slug}`;

  return (
    <div className="page-item fullscreen">
      {/* Display video if available; otherwise, fallback to images */}
      {videoUrl ? (
        <video
          src={videoUrl}
          autoPlay
          muted
          controls={false}
          loop
          disablePictureInPicture
          controlsList="nodownload"
          className="fullscreen-media"
        />
      ) : imageUrl ? (
        <img src={imageUrl} alt={item.title} className="fullscreen-media" />
      ) : (
        <div>No media available</div>
      )}

      {/* Page Information */}
      <div className="page-info">
        <h2 className="font-lg m-0 mb-4">{item.title}</h2>
        <Link to={pageLink} className="page-link">See more</Link>
      </div>
    </div>
  );
};

export default PageItem;
