import React, { useEffect, useState } from 'react';
import { Helmet as ReactHelmet } from 'react-helmet-async';
import FetchSite from './FetchSite'; 

// Destructure the props and provide default values
const Helmet = ({
  title = 'Narrada Communications | Every Brand Needs a Beautiful Story',
  description = 'Welcome to Narrada, a leading digital agency specializing in digital campaigns.',
  ogImage = 'https://d1vkr02c9udppg.cloudfront.net/ee187bad-13ba-4428-9ac0-ca8fa929ee01.png',
  keywords = 'Digital Agency, Advertising, Digital Campaign',
  ogUrl, // Allow `ogUrl` to be passed in or use a fallback later
}) => {
  const [siteData, setSiteData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await FetchSite();
        setSiteData(result.data); // Assuming the data is under "data"
      } catch (error) {
        console.error('Error fetching site data:', error);
      }
    };

    fetchData();
  }, []);

  if (!siteData) {
    return (
      <ReactHelmet>
        <title>Loading...</title>
      </ReactHelmet>
    );
  }

  return (
    <ReactHelmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={ogImage} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={ogImage} />
      <meta name="keywords" content={keywords} />
      <meta property="og:url" content={ogUrl || siteData.site_url} /> {/* Fallback to siteData */}
    </ReactHelmet>
  );
};

export default Helmet;
