import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import ArrowLeft from '../assets/img/arrow-icon-left.png';
import ArrowRight from '../assets/img/arrow-icon-right.png';

const AwardsCarousel = ({ awards }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef(null);

  // Handle next slide
  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % awards.length);
  };

  // Handle previous slide
  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? awards.length - 1 : prevIndex - 1
    );
  };

  // Handle dot navigation
  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  if (awards.length === 0) {
    return <div>No awards to display</div>;
  }

  return (
    <div
      className="awards-carousel"
      style={{ height: 'calc(var(--vh, 1vh) * 100)' }}
    >
      <div className="carousel-container">
        {/* Disable previous/next buttons if only one item */}
        {awards.length > 1 && (
          <button className="carousel-control prev" onClick={handlePrev}>
            <img src={ArrowLeft} alt="left arrow"/>
          </button>
        )}
        <div
          className="carousel-content"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          ref={carouselRef}
        >
          {awards.map((award, idx) => (
            <div className="carousel-item" key={award.id}>
              {/* Display first image */}
              <img
                src={
                  award.images?.[0]?.directus_files_id
                    ? `https://d1vkr02c9udppg.cloudfront.net/${
                        typeof award.images[0].directus_files_id === 'object'
                          ? award.images[0].directus_files_id.id
                          : award.images[0].directus_files_id
                      }.jpg`
                    : '/path-to-placeholder-image.jpg' // Fallback image
                }
                alt={award.title}
                className="carousel-image"
              />
              <div className="carousel-text">
                <h2>{award.title}</h2>
                <Link to={`/awards/${award.slug}`}>Read More</Link>
              </div>
            </div>
          ))}
        </div>
        {awards.length > 1 && (
          <button className="carousel-control next" onClick={handleNext}>
            <img src={ArrowRight} alt="right arrow"/>
          </button>
        )}

        {/* Disable dots if only one item */}
        {awards.length > 1 && (
          <div className="carousel-dots">
            {awards.map((_, index) => (
              <span
                key={index}
                className={`dot ${index === currentIndex ? 'active' : ''}`}
                onClick={() => handleDotClick(index)}
              ></span>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default AwardsCarousel;
