import React, { useEffect, useState } from 'react';
import FetchData from '../components/FetchData'; // Adjust the path if necessary

const ClientList = () => {
  const [content, setContent] = useState(null);
  const cloudfrontUrl = process.env.REACT_APP_DATA_CLOUDFRONT_URL; // Assuming you're using CloudFront for images

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const data = await FetchData(); // Fetch all data from your FetchData.js
        const aboutContent = data.pages.find(item => item.slug === 'client'); // Find the page with the slug 'client'
        
        if (aboutContent) {
          setContent(aboutContent);
        } else {
          console.error('No client page found.');
        }
      } catch (error) {
        console.error('Error fetching client content:', error);
      }
    };

    fetchContent();
  }, []);

  if (!content) return <div>Loading...</div>;

  return (
    <div className="page-container pdc pt-20 lg:pt-6">
      <h2 className="title text-center font-sm">{content.title}</h2>
      <div className="content-page">
        <div className='client-box-cont'>
          {content.images && content.images.map((image, idx) => (
            <div className='client-list-cont' key={idx}>
              <img 
                className='client-item-img' 
                src={`${cloudfrontUrl}${image.directus_files_id.filename_disk}`} 
                alt={image.directus_files_id.title || 'Client Logo'} 
              />
            </div>
          ))}
        </div>
        {/* <p className="text-paragraph mb-5" dangerouslySetInnerHTML={{ __html: content.description }} /> */}
      </div>
    </div>
  );
};

export default ClientList;
