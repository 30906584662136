import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import menuIcon from '../assets/img/hamburger.png';
import NrdNetwork from '../assets/img/logo-narrada-network.png';


const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation(); 

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  // Helper function to check if a link is active
  const isActive = (path) => location.pathname === path ? 'active' : '';

  return (
    <>
      <div className="navbar">
        <div className="navbar-links">
          <Link to="/work" className={isActive('/work')} onClick={closeMenu}>Works</Link>
          <Link to="/awards" className={isActive('/awards')} onClick={closeMenu}>Awards</Link>
          <Link to="/contact" className={isActive('/contact')} onClick={closeMenu}>Contact Us</Link>
        </div>
        <button className="navbar-menu-button" onClick={toggleMenu}>
          <img src={menuIcon} alt="Menu" />
        </button>
      </div>

      {isMenuOpen && (
        <div className="navbar-popup-overlay" onClick={toggleMenu}>
          <div className="navbar-popup" onClick={(e) => e.stopPropagation()}>
            <ul>
              <li><Link to="/about" className={isActive('/about')} onClick={closeMenu}>About Us</Link></li>
              <li><Link to="/client" className={isActive('/client')} onClick={closeMenu}>Client</Link></li>
              {/* <li><Link to="/team" className={isActive('/team')} onClick={closeMenu}>Storymaker</Link></li> */}
              <li><Link to="/activities" className={isActive('/activities')} onClick={closeMenu}>Activities</Link></li>
            </ul>
            <div className="partner-cont">
              <div className="partner-text">
                <p>
                We partner with the best to enhance our services for you.
                We are now qualified as a Google Partner and Meta Business Partner.
                </p>
              </div>
              <div className="partner-logos">
                <a href="https://www.facebook.com/business/partner-directory/search?solution_type=campaign_management&ref=fmp_nav_bar&countries=ID&id=4560953363921050&section=overview" target="_blank" rel="noreferrer"><img src="/assets/img/meta-partner-logo.png" alt="Meta Business Partner" className="partner-logo" /></a>
                <a href="https://www.google.com/partners/agency?id=4894937497" target="_blank" rel="noreferrer"><img src="/assets/img/google-partner-logo.jpg" alt="Google Partner" className="partner-logo" /></a>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Desktop Navbar */}
      <div className="navbar-desktop">
        <div className="navbar-desktop-links">
        <Link to="/" className={isActive('/')}>Home</Link>
          <Link to="/work" className={isActive('/work')}>Works</Link>
          <Link to="/awards" className={isActive('/awards')}>Awards</Link>
          <Link to="/client" className={isActive('/client')}>Client</Link>
          <Link to="/activities" className={isActive('/activities')}>Activities</Link>
          <Link to="/contact" className={isActive('/contact')}>Contact Us</Link>
          <Link to="/about" className={isActive('/about')}>About Us</Link>
          {/* <Link to="/team" className={isActive('/team')}>Storymaker</Link> */}
          
        </div>
        <div className='logo-network-cont'>
          <img src={NrdNetwork} alt="Narrada Network" />
        </div>
      </div>
    </>
  );
};

export default Navbar;
