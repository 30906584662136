import React, { useRef, useState } from 'react';
import ArrowLeft from '../assets/img/arrow-icon-left.png';
import ArrowRight from '../assets/img/arrow-icon-right.png';

// Group medals by year
const groupMedalsByYear = (medals = []) => {
  const groupedMedals = {};

  medals.forEach((medal) => {
    const year = medal.year; // Each medal has a `year`
    if (!groupedMedals[year]) {
      groupedMedals[year] = [];
    }

    // Add each medal to its respective year group
    groupedMedals[year].push({
      description: medal.description,
      image: medal.images?.filename_disk, // Access the medal's image
    });
  });

  return groupedMedals;
};

const AwardList = ({ medals = [] }) => {
  const carouselRef = useRef(null);
  const touchStartXRef = useRef(0);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  // Group medals by year
  const groupedMedals = groupMedalsByYear(medals);
  const years = Object.keys(groupedMedals).sort((a, b) => b - a); // Sort years in descending order

  // Handle navigation (next and previous)
  const handlePrev = () => {
    setCurrentSlideIndex((prevIndex) =>
      prevIndex === 0 ? years.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentSlideIndex((prevIndex) =>
      prevIndex === years.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleTouchStart = (e) => {
    touchStartXRef.current = e.touches[0].clientX;
  };

  const handleTouchEnd = (e) => {
    const touchEndX = e.changedTouches[0].clientX;
    if (touchStartXRef.current - touchEndX > 50) {
      handleNext();
    } else if (touchStartXRef.current - touchEndX < -50) {
      handlePrev();
    }
  };

  return (
    <div className="award-list-layout">
      <div className="content-item">
        <div
          className="carousel"
          ref={carouselRef}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
        >
          <button className="carousel-control prev" onClick={handlePrev}>
          <img src={ArrowLeft} alt="left arrow" />
          </button>
          <div
            className="carousel-content"
            style={{ transform: `translateX(-${currentSlideIndex * 100}%)` }}
          >
            {years.map((year, idx) => (
              <div className="carousel-item px-0.5" key={`${year}-${idx}`}>
                <h2>{year}</h2>
                <div className="medals-list">
                  {groupedMedals[year].map((medal, index) => (
                    <div key={`${year}-${index}`} className="medal-item">
                      {/* Display the medal image */}
                      <img 
                        src={`https://d1vkr02c9udppg.cloudfront.net/${medal.image}`} 
                        alt={`Medal ${index + 1}`} 
                        className="medal-image"
                      />
                      <p>{medal.description}</p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <button className="carousel-control next" onClick={handleNext}>
          <img src={ArrowRight} alt="right arrow" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default AwardList;
