import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import fetchData from '../components/FetchData';
import Sidebar2 from '../components/Sidebar2'; // Updated import

const AwardDetail = () => {
  const { slug } = useParams(); // Get slug from the URL
  const [awardItem, setAwardItem] = useState(null);
  const [allMedals, setAllMedals] = useState([]); // State to store all medals data
  const [view, setView] = useState('postview'); // Default view
  const cloudfrontUrl = process.env.REACT_APP_DATA_CLOUDFRONT_URL;

  useEffect(() => {
    const fetchAwardDetail = async () => {
      try {
        const data = await fetchData(); // Fetch all data
        

        // Check if data has necessary properties
        if (!data || typeof data !== 'object' || !data.awards || !data.medals) {
          console.error('Data does not contain "awards" or "medals" property:', data);
          return;
        }

        // Extract awards and medals data
        const awardsData = data.awards;
        const medalsData = data.medals;
        setAllMedals(medalsData); // Store medals data for use later

        if (!Array.isArray(awardsData)) {
          console.error('Awards data is not an array:', awardsData);
          return;
        }

        // Find the award by slug
        const award = awardsData.find(item => item.slug === slug);
        if (!award) {
          console.error(`No award found for slug: ${slug}`);
          return;
        }

        setAwardItem(award);
      } catch (error) {
        console.error('Error fetching award detail:', error);
      }
    };

    if (slug) {
      fetchAwardDetail();
    } else {
      console.error('Slug is undefined');
    }

    // Check URL hash for view state on initial render
    const currentHash = window.location.hash.replace('#', '');
    if (currentHash === 'listview' || currentHash === 'postview') {
      setView(currentHash);
    }
  }, [slug]);

  const toggleViewMode = (newView) => {
    setView(newView);
    window.location.hash = newView; // Update the hash in the URL
  };

  if (!cloudfrontUrl) {
    console.error('CloudFront URL is not defined. Check your .env file.');
    return <p>Error: CloudFront URL is not defined</p>;
  }

  if (!awardItem) {
    return <p>Loading...</p>; // Show loading if data isn't loaded yet
  }

  const { youtube_url, title, campaign_logo, campaign_name, description, medals, images } = awardItem;

  const hasCampaignData = campaign_logo?.trim() && campaign_name?.trim();

  // Fallback image: If youtube_url is missing, display the first image from the images array
  const fallbackImage = images && images.length > 0 
    ? `${cloudfrontUrl}${images[0].directus_files_id.filename_disk}` 
    : null;

  // Helper function to find the medal data in the medals collection
  const getMedalDetails = (medalItemId) => {
    return allMedals.find(medal => medal.id === medalItemId);
  };

  return (
    <div className="award-detail-page pt-24 pb-36">
      <Sidebar2
        toggleViewMode={toggleViewMode} 
        viewMode={view} // Pass the current view mode
      />
      {/* Conditional Rendering Based on View State */}
      {view === 'postview' ? (
        <div>
          {/* YouTube Video or Fallback Image */}
          {youtube_url ? (
            <div className="youtube-section">
              <iframe
                width="100%"
                src={youtube_url.replace('watch?v=', 'embed/')}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="fullscreen-media"
                title="YouTube video player"
              />
            </div>
          ) : (
            fallbackImage && (
              <div className="image-section">
                <img src={fallbackImage} alt="Award" />
              </div>
            )
          )}

          {/* Client Tag */}
          {hasCampaignData && (
            <div className="client-tag">
              <div className="client-logo">
                <img
                  className={`${campaign_logo}-logo`}
                  src={`../assets/img/${campaign_logo}-logo.png`}
                  alt={`${campaign_name} logo`}
                />
              </div>
              <span>{campaign_name}</span>
            </div>
          )}

          <h2 className="content-title mb-15">{title}</h2>
          <div className='content-text mb-15 pr-10' dangerouslySetInnerHTML={{ __html: description }} />

          {/* Medals List */}
          {medals && medals.length > 0 && (
            <div className="medals-list">
              {medals.map((medal, idx) => {
                // Find the medal details in the medals collection
                const medalDetails = getMedalDetails(medal.item);
                if (!medalDetails) {
                  console.error(`No medal found with id: ${medal.item}`);
                  return null;
                }

                // Get the medal image and description
                const medalImage = medalDetails.images ? `${cloudfrontUrl}${medalDetails.images.filename_disk}` : null;
                const medalDescription = medalDetails.description || `Medal ${idx + 1}`;

                return (
                  <div key={idx} className="medal-item">
                    {medalImage && (
                      <img
                        src={medalImage}
                        alt={`Medal ${idx + 1}`}
                      />
                    )}
                    <p>{medalDescription}</p>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      ) : (
        <div className="list-view">
          {/* List View Content Here */}
          {medals && medals.length > 0 && (
            <div className="medals-list">
              {medals.map((medal, idx) => {
                const medalDetails = getMedalDetails(medal.item);
                if (!medalDetails) {
                  console.error(`No medal found with id: ${medal.item}`);
                  return null;
                }
                const medalImage = medalDetails.images ? `${cloudfrontUrl}${medalDetails.images.filename_disk}` : null;
                const medalDescription = medalDetails.description || `Medal ${idx + 1}`;
                return (
                  <div key={idx} className="medal-item">
                    {medalImage && (
                      <img
                        src={medalImage}
                        alt={`Medal ${idx + 1}`}
                      />
                    )}
                    <p>{medalDescription}</p>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
      
    </div>
    
  );
};

export default AwardDetail;
