import axios from 'axios';

// Fetch data from various endpoints using Directus API token from .env
const fetchData = async () => {
  try {
    const token = process.env.REACT_APP_DIRECTUS_API_TOKEN;
    const cloudfrontUrl = process.env.REACT_APP_DATA_CLOUDFRONT_URL;

    
    if (!token) {
      throw new Error('API token is not set. Please check your .env file.');
    }
    
    if (!cloudfrontUrl) {
      throw new Error('CloudFront URL is not set. Please check your .env file.');
    }

    // Set up headers with the token for authorization
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    // Function to fetch data and handle the response
    const fetchEndpoint = async (url) => {
      const response = await axios.get(url, { headers });
      if (response.data && response.data.data) {
        return response.data.data.filter(item => item.status === 'published');
      } else {
        console.warn(`Unexpected response structure from ${url}:`, response.data);
        return [];
      }
    };

    // Generate media URL using the CloudFront URL from .env
    const generateMediaUrl = (fileId) => {
      return `${cloudfrontUrl}${fileId}`;
    };

    const replaceUrlInDescription = (description) => {
      if (!description) return description;
      
      const updatedDescription = description.replace(
        /https:\/\/cms-nrd\.app-row\.com\/assets\//g,
        `${cloudfrontUrl}`
      );
      return updatedDescription;
    };

    // Fetch data from each endpoint using URLs from .env
    const posts = await fetchEndpoint(process.env.REACT_APP_DATA_URL);
    const categories = await fetchEndpoint(process.env.REACT_APP_DATA_CATEGORIES_URL);
    // const people = await fetchEndpoint(process.env.REACT_APP_DATA_TEAM_URL);
    const awards = await fetchEndpoint(process.env.REACT_APP_DATA_AWARD_URL);
    const medals = await fetchEndpoint(process.env.REACT_APP_DATA_MEDALS_URL);
    const clients = await fetchEndpoint(process.env.REACT_APP_DATA_CLIENTS_URL);
    const pages = await fetchEndpoint(process.env.REACT_APP_DATA_PAGES_URL);

    // Modify posts to include media URLs, map category IDs to slugs
    const postsWithMediaAndCategorySlug = posts.map((post) => ({
      ...post,
      mediaUrl: post.media_id ? generateMediaUrl(post.media_id) : null, // Adjust if your media field name is different
      categorySlug: post.category ? post.category.slug : 'uncategorized', // Map category object to slug, default to 'uncategorized' if not found
      campaignLogoUrl: post.campaign_logo ? generateMediaUrl(post.campaign_logo.filename_disk) : null, // Generate URL for campaign logo
      videoUrl: post.video ? generateMediaUrl(post.video.filename_disk) : null, // Generate URL for video
    }));

    // Modify pages to replace description URLs
    const pagesWithUpdatedDescriptions = pages.map((page) => ({
      ...page,
      description: replaceUrlInDescription(page.description), // Replace URLs in the description for pages
    }));

    // Combine all fetched data into a single object
    const combinedData = {
      posts: postsWithMediaAndCategorySlug || [],
      categories: categories || [],
      // people: people || [],
      awards: awards || [],
      medals: medals || [],
      clients: clients || [],
      pages: pagesWithUpdatedDescriptions || [], // Use the transformed pages here
    };

    console.log('Fetched Combined Data:', combinedData);
    return combinedData;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export default fetchData;
